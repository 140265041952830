import { Link, useParams } from "react-router-dom";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import "./Card.scss";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

 import { ToastContainer, toast } from "react-toastify";
 import "react-toastify/dist/ReactToastify.css";


const Card = ({ item }) => {
  const dispatch = useDispatch();

  const cartData = useSelector((state) =>
    state.cart.products?.find((i) => i.id === item.id)
  );

  const quantity = cartData?.quantity || 0;
  const products = useSelector((state) => state.cart.products);

  const getQuantityInCart = () => {
    const cartItem = products.find((item) => item.id);
    return cartItem
      ? {
          id: cartItem.id,
          max_order_value: cartItem.max_order_qut,
          quantity: cartItem.quantity,
          
        }
      : { id: null, max_order_value: 2, quantity: 2 }; // Default values for quantity if no item is found
  };

  const cartDatas = getQuantityInCart();


  const handleAddToCart = () => {
    // Get the number of items in the cart with flash_sale === true
    const flashSaleItemsInCart = products.filter(
      (product) => product.flash_sale === true
    ).length;

    if (item.attributes.outofstock) {
      toast.error("Temporary out of stock", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          color: "white",
          boxShadow: "none",
          backgroundColor: "#FFD6D7",
          color: "black",
        },
      });
    } else if (
      item.attributes.flash_sale &&
      flashSaleItemsInCart >= item.attributes.flash_sale_product_pcs
    ) {
      // Prevent adding more than 3 flash_sale items
      toast.error("You can only add up to 3 flash sale items to the cart", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          color: "white",
          boxShadow: "none",
          backgroundColor: "#FFD6D7",
          color: "black",
        },
      });
    } else if (
      quantity < item.attributes.max_order_qut ||
      item.attributes.max_order_qut !== 2
    ) {
      // Adds item to cart if max order limit is not reached or it has a different max order limit
      dispatch(
        addToCart({
          id: item.id,
          title: item.attributes.title,
          desc: item.attributes.desc,
          weight: item.attributes.weight,
          price: item.attributes.price,
          img: item.attributes.img.data.attributes.url,
          quantity: 1,
          max_order_qut: item.attributes.max_order_qut,
          flash_sale: item.attributes.flash_sale,
          flash_sale_product_pcs: item.attributes.flash_sale_product_pcs,
        })
      );
    }
  };


  const showToastMessage = () => {
    toast.success("Success Notification !", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  return (
    <>
      <div className="card">
        <div className="card_in">
          <div className="image">
            {item.attributes.discount && (
              <span> {item.attributes.discoamount}</span>
            )}
            <span2> {item.attributes.outofstock && "out of stock"} </span2>

            <img
              src={
                process.env.REACT_APP_UPLOAD_URL +
                item.attributes?.img?.data?.attributes?.url
              }
              alt=""
              className="mainImg"
            />
            <img
              src={
                process.env.REACT_APP_UPLOAD_URL +
                item.attributes?.img2?.data?.attributes?.url
              }
              alt=""
              className="secondImg"
            />
          </div>

          <div className="data">
            <br />
            <p>{item.attributes.title}</p>
            <small> Weight: {item.attributes.weight}</small>
            <div className="prices">
              <h2>{item?.attributes.oldprice}</h2>
              <h1>
                <small>AED</small> {item?.attributes.price}
              </h1>
            </div>

            {cartDatas === item.id ? (
              <div className="addtocart">
                <div className="add">
                  <AddShoppingCartIcon fontSize="40" />
                  ADD TO CART
                </div>
              </div>
            ) : (
              <div className="addtocart">
                <div className="add" onClick={handleAddToCart}>
                  <AddShoppingCartIcon fontSize="40" />
                  ADD TO CART
                </div>
              </div>
            )}
          </div>
          <Link to={`/product/${item.id}`}>
            <div style={{ marginTop: "10px", padding: "5px 30px" }}>
              <span>Learn More</span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Card;
